<template>
  <div class="container">
    <div class="checkin">
      <el-row gutter="20">
        <el-col span="5">
          <memberleft :activeIndex="leftPannelSelected"/>
        </el-col>
        <el-col span="19">
          <div class="right_pannel" style="padding: 0;">
            <div class="pannel-top" style="width:100%; height:150px;">
              <img src="https://static.a6air.com/resource/banner/20210330/pannel_top_order.jpg" alt="">
            </div>
            <div style="margin:5px;background-color:#ffffff;  border-radius: 2px">
              <div class="order-search">
                <el-row>
                  <el-col span="12">
                    <ul class="service-forms">
                      <li class="form-group">
                        <!-- <label class="form-label" for="certNo">下单时间：</label> -->
                        <el-date-picker
                          v-model="orderSearchFilter.createTimeStart"
                          type="date"
                          value-format="yyyy-MM-dd"
                          placeholder="创单开始日期"
                          style="width:150px;"
                        ></el-date-picker>
                        <span style="padding: 0 5px;">至</span>
                        <el-date-picker
                          v-model="orderSearchFilter.createTimeEnd"
                          type="date"
                          value-format="yyyy-MM-dd"
                          placeholder="创单结束日期"
                          style="width:150px;"
                        ></el-date-picker>
                      </li>
                    </ul>
                  </el-col>
                  <el-col span="6">
                    <ul class="service-forms">
                      <li class="form-group">
                        <!-- <label class="form-label" for="passengerName">订单状态：</label> -->
                        <el-select
                          v-model="orderSearchFilter.orderStatus"
                          multiple
                          style="width:250px;margin-left:20px;"
                          :clearable="true"
                          placeholder="订单状态"
                        >
                          <el-option
                            v-for="item in options"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                          ></el-option>
                        </el-select>
                      </li>
                    </ul>
                  </el-col>
                  <div style="width:100%;text-align:center;padding-bottom:10px;margin-top:20px;">
                    <el-button type="danger" style="width:120px;" @click="loadOrderList(1)">查&nbsp;询</el-button>
                  </div>
                </el-row>
                <!-- <div style="width:100%;text-align:center;padding-bottom:10px;margin-top:20px;">
                  <el-button type="danger" style="width:120px;" @click="loadOrderList(1)">查&nbsp;询</el-button>
                </div>-->
              </div>
            </div>
            <div
              class="aoctable"
              style="min-height:400px; border-radius: 10px;-top:10px;margin:5px;background-color:#ffffff; "
            >
              <el-table :data="tableData" style="width: 100%;border-radius: 10px;" v-loading="orderLoadingStatus">
                <el-table-column type="index" label="序号" align="center"></el-table-column>
                <el-table-column prop="flightNo" label="航班号" width="80" align="center"></el-table-column>
                <el-table-column prop="depAirport" label="行程" width="150">
                  <template
                    slot-scope="scope"
                  >
                  <div style="display: flex;justify-content: center;align-items: center;">
                    {{ scope.row.depCityName }}
                    <div style="display: flex;">
                      <img v-if="scope.row.tripType == 'RT'" src="../../assets/flight-gr.png">
                      <img v-if="scope.row.tripType == 'OW'" src="../../assets/flight-rn.png">
                    </div>
                    {{ scope.row.arrCityName }}
                    </div></template>
                </el-table-column>
                <el-table-column label="起飞日期" width="160">
                  <template slot-scope="scope">{{ scope.row.depDatetime | fromNowShort }}</template>
                </el-table-column>
                <el-table-column prop="orderAmount" label="订单金额" width="90">
                  <template
                    slot-scope="scope"
                  >¥{{ scope.row.orderAmount }}
                  </template>
                </el-table-column>
                <el-table-column label="创单时间" width="160">
                  <template slot-scope="scope">{{ scope.row.createTime | fromNow }}</template>
                </el-table-column>
                <el-table-column prop="orderStatusDesc" label="订单状态" width="80" align="center"></el-table-column>
                <el-table-column label="" align="center">
                  <template slot-scope="scope">
                    <el-link
                      @click="gotoDetail(scope.row.serialNo)"
                      v-if="scope.row.orderStatus!='WP'"
                    >查看</el-link>
                    <el-link
                      @click="gotoCancelOrder(scope.row.serialNo)"
                      v-if="scope.row.orderStatus=='WP'"
                    >取消</el-link>
                    <span style="padding: 0 5px;" v-if="scope.row.orderStatus=='WP'">|</span>
                    <el-link
                      @click="goPay(scope.row.serialNo)"
                      v-if="scope.row.canPay"
                    >支付</el-link>
                  </template>
                </el-table-column>
              </el-table>
              <el-pagination
                :page-size="pageData.pageSize"
                :pager-count="pageData.totalPage"
                layout="prev, pager, next"
                :total="pageData.total"
                :current-page="pageData.page"
                align="right"
                @current-change="loadOrderList"
                @next-click="loadOrderList"
              ></el-pagination>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import moment from "moment";
import { orderList, orderCancel } from "@/api/newsapi";
import memberleft from "../../components/MemberLeft";
import { CURRENT_USER } from "@/store/mutation-types";
export default {
  components: { memberleft },
  data() {
    return {
      input: "",
      orderLoadingStatus: false,
      orderSearchFilter: {
        orderStatus: [],
        createTimeStart: "",
        createTimeEnd: "",
        currentPage: 0,
        pageSize: 20,
        sortType: "desc"
      },
      leftPannelSelected: "orderlist",
      pageData: { pageSize: 20, page: 1, totalPage: 5, total: 100 },
      tableData: [],
      options: [
        { key: "WP", label: "等待支付", value: "WP" },
        { key: "PF", label: "支付失败", value: "PF" },
        { key: "PT", label: "待出票", value: "PT" },
        { key: "II", label: "出票中", value: "II" },
        { key: "IW", label: "出票完成", value: "IW" },
        { key: "DF", label: "出票失败", value: "DF" },
        { key: "XX", label: "订单取消", value: "XX" }
      ]
    };
  },
  filters: {
    fromNow(date) {
      return moment(date).format("YYYY-MM-DD HH:mm:ss");
    },
    fromNowShort(date) {
      return moment(date).format("YYYY-MM-DD HH:mm");
    }
  },
  mounted() {
    this.currentUser = Vue.ls.get(CURRENT_USER);
    if (
      this.currentUser == null ||
      this.currentUser.phone == undefined ||
      this.currentUser.phone.length <= 0
    ) {
      this.$router.push({ path: "/login", query: { t: new Date().getTime() } });
      return;
    }
    this.loadOrderList(1);
  },
  methods: {
    selectPannel(pannelId) {
      document.getElementById("li_passlist").setAttribute("class", "");
      document.getElementById("li_order").setAttribute("class", "");
      document.getElementById("li_changepwd").setAttribute("class", "");
      document.getElementById("li_userinfo").setAttribute("class", "");
      document.getElementById(pannelId).setAttribute("class", "active");
    },
    gotoDetail(orderNo) {
      this.$router.push({
        path: "/order/details/" + orderNo,
        // path:"/orderreview/"+orderNo,
        query: { t: new Date().getTime() }
      });
    },
    goPay(orderNo){
      this.$router.push({
        // path: "/order/details/" + orderNo,
        path:"/orderreview/"+orderNo,
        query: { t: new Date().getTime() }
      });
    },
    gotoCancelOrder(orderNo) {
      this.$confirm("当前座位紧张，请确认是否立即取消此订单？", "温馨提示", {
        distinguishCancelAndClose: true,
        center:false,
        confirmButtonText: "立即取消",
        cancelButtonText: "再等一等",
        cancelButtonClass:'cancelBtn',
        customClass: "m-message-box"
      }).then(() => {
        this.cancelOrderHandler(orderNo);
      });
    },
    cancelOrderHandler(orderNo) {
      orderCancel({ t: orderNo }).then(res => {
        if (res.success) {
          this.loadOrderList(1);
        }
      });
    },
    loadOrderList(page) {
      console.log(page);
      this.orderLoadingStatus = true;
      this.orderSearchFilter.currentPage=page;
      orderList({ t: this.orderSearchFilter }).then(res => {
        this.orderLoadingStatus = false;
        if (res.success) {
          this.pageData.totalPage = res.data.totalPage;
          this.tableData = res.data.data;
          this.pageData.total = res.data.count;
          console.log(res);
        } else {
          this.flights = [];
        }
      });
    }
  }
};
</script>
<style scoped lang='less'>
  /* .cancelBtn{
    background-color: white !important;
    color: #0e0903;
  } */
.checkin {
  margin-top: 20px;
  margin-bottom: 20px;
}
.left_pannel {
  height: 500px;
  background-color: #ffffff;
  overflow: hidden;
}
.right_pannel {
  position: relative;
  background-color: #ffffff;
  /* border: 1px solid #ededed; */
}
.col_menu b {
  font-size: 24px;
  margin: 20px 20px 0;
  color: #333;
  display: block;
  font-weight: normal;
  border-bottom: 1px solid #f1f1f1;
}
.col_menu ul {
  margin-bottom: 20px;
}
ul,
ol,
dl,
dd,
dt {
  margin: 0;
  padding: 0;
  display: block;
}
.col_menu li {
  margin: 10px 0;
  position: relative;
}
.col_menu li.current {
  background: none;
}
.col_menu li.current a:link,
.col_menu li.current a:visited,
.col_menu li.current a:active {
  border-left: 2px #ff6600 solid;
  color: #666;
  font-weight: bold;
}
.col_menu li a:link,
.col_menu li a:visited,
.col_menu li a:active {
  display: block;
  line-height: 30px;
  padding: 0 55px 0 20px;
}
a:link,
a:visited,
a:active {
  color: #666;
  text-decoration: none;
}

a {
  color: #337ab7;
  text-decoration: none;
}
.warm-prompt {
  padding: 40px 50px 80px;
}
.warm-prompt p {
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
  color: #9d9d9d;
  text-align: left;
}

.order-search .service-title {
  font-size: 18px;
  font-weight: 700;
  line-height: 55px;
  color: #4d4e4e;
  text-align: center;
  border-bottom: 1px solid #eae7e7;
}
.order-search .service-prompt {
  padding-top: 8px;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.8;
  color: #9d9d9d;
  text-align: center;
}
.order-search .service-forms > li {
  margin-top: 20px;
}

.form-group .form-label {
  width: 100px;
  display: inline-block;
  font-size: 14px;
  font-weight: 400;
  line-height: 35px;
  color: #4d4e4e;
  text-align: right;
}
.checkin-links.query {
  margin: 24px 35px 24px 75px !important;
}

.agree-protocol {
  margin: 24px 0;
  padding-left: 24px;
  font-size: 0;
}
.link.link-sm {
  height: 18px;
  font-size: 12px;
  line-height: 18px;
}

.link {
  height: 20px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #2c6e8b;
  text-align: left;
}

.search-block {
  width: 1200px;
  margin: auto;
  position: relative;
}
.popcontext .tab {
  z-index: 1000;
  width: 240px;
}
.popcontext .tab li {
  height: 60px;
  line-height: 60px;
  /* border-top: 1px solid #4e617f; */
  background-color: #ffffff;
  color: #000000;
  font-size: 14px;
  text-align: center;
  transition: all 0.5s ease 0s;
  -webkit-transition: all 0.5s ease 0s;
  cursor: pointer;
  margin-left: 10px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  filter: alpha(Opacity=60);
  -moz-opacity: 0.6;
  opacity: 0.6;
}

.popcontext .tab li:last-child {
  -webkit-border-bottom-left-radius: 5px;
  -moz-border-bottom-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.popcontext .tab li.active {
  /* border-top: 1px solid #fff; */
  background-color: #d6003a;
  font-size: 16px;
  color: #ffffff;
  font-weight: bold;
  margin-left: 0;
  padding-left: 10px;
  border-right: 1px solid #fff;
  -webkit-border-radius: 3px 0 0 3px;
  -moz-border-radius: 3px 0 0 3px;
  border-radius: 3px 0 0 3px;
  filter: alpha(Opacity=100);
  -moz-opacity: 1;
  opacity: 1;
}
.popcontext hgroup {
  display: none;
  position: absolute;
  width: 460px;
  top: -410px;
  left: 0px;
  z-index: 1000;
  margin-left: 140px;
  background-color: #fff;
  -webkit-border-radius: 0 5px 5px 0;
  -moz-border-radius: 0 5px 5px 0;
  border-radius: 0 5px 5px 0;
}
.popcontext hgroup article {
  position: relative;
  height: 364px;
  padding-left: 30px;
}
.pannel-top {
  img{
    width: 100%;
    height: 150px;
    border-radius: 10px 10px 0 0;
  }
  /* background-image: url("../../assets/member-new-1.png");
  border-radius: 10px 10px 0 0;
  background-size: 100% 100%; */
}
</style>
